.layout_container {
  flex: 1;
  // width: 1184px;
  background: #ffffff;
  // margin: 14px 0 34px 0;
  position: relative;
}

/**
* layout = ( header ) + ( menu + (container = CommonTitle + layout_content))
*/
.layout_content {
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: @safe-padding;
  overflow: auto;
}

.layout_content_noPadding {
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  overflow: auto;
}

