/* 与布局、排版有关的全局样式名 */
.flex {
  display: flex;
}

.flex-r-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-r-start-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-r-start-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-r-between-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-r-end-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.flex-r-start-stretch {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.flex-c-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-c-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex-c-start-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-d-row {
  flex-direction: row;
}

.flex-d-column {
  flex-direction: column;
}

.row-wrap {
  flex-flow: row wrap;
}

.row-nowrap {
  flex-flow: row nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 垂直居中 */
.flex-vc,
.flex-a-center {
  align-items: center;
}

.flex-a-start {
  align-items: flex-start;
}

.flex-a-end {
  align-items: flex-end;
}

.flex-a-baseline {
  align-items: baseline;
}

.flex-a-stretch {
  align-items: stretch;
}

.flex-hc,
.flex-j-center {
  justify-content: center;
}

.flex-j-between {
  justify-content: space-between;
}

.flex-j-around {
  justify-content: space-around;
}

.flex-j-start {
  justify-content: flex-start;
}

.flex-j-end {
  justify-content: flex-end;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flex3 {
  flex: 3;
}

.vat {
  vertical-align: top;
}

.vab {
  vertical-align: bottom;
}

.vatp {
  vertical-align: text-top;
}

.vatb {
  vertical-align: text-bottom;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.p-fixed {
  position: fixed;
}

.top0 {
  top: 0;
}

.bottom0 {
  bottom: 0;
}

.left0 {
  left: 0;
}

.right0 {
  right: 0;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.uef-upload {
  .up-dt {
    display: flex;
    align-items: center;

    .del {
      position: relative;
      top: -2px;
      color: red;
      font-size: 18px;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .ant-upload-list {
    max-height: 300px;
    overflow: auto;
    border: 1px solid #d9d9d9;
    padding: 15px;
  }
}

@keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.pre-loading {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  &.grep {
    background: rgb(43, 43, 43);
    color: #fff;
  }

  .msg {
    margin-left: 10px;
    font-size: 14px;
  }

  .icon {
    font-size: 18px;
    margin-right: 5px;
    position: relative;
    top: 2px;
  }

  .circle {
    width: 35px;
    height: 35px;
    animation: circle infinite 0.75s linear;
    border: 2px solid #198bfd;
    border-top-color: transparent;
    border-radius: 100%;
  }
}

.render_right_del {
  color: red;
  cursor: pointer;
  margin: 0 5px;

  &:hover {
    color: #ff6a6a;
  }
}

.render_right_edit {
  color: #1890ff;
  cursor: pointer;
  margin: 0 5px;

  &:hover {
    color: #1bb7ff;
  }
}

.header__searchs {
  display: flex;
  align-items: center;
  justify-content: right;
  margin: 0 0 0 0;

  // border-bottom: 1px solid #ebeff6;
  .ant-btn-primary {
    background: #68c23b;
    border-color: #68c23b;

    &:hover {
      background: #75da42;
      border-color: #75da42;
    }
  }
}

.header_searchs_reset {
  color: #dcdcdc;
  font-size: 14px;
  margin-left: 6px;
  margin-right: 4px;
  cursor: pointer;
  width: 48px;

  &:hover {
    color: #68c23b;
  }
}

.image_frame_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  border-top: 1px solid rgb(208, 208, 216);

  .image_frame_box {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgb(43, 43, 43);
    iframe {
      height: 100%;
      width: calc(100% + 22px);
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.home-dg-editor {
  .ant-form-item {
    display: flex;
  }

  .ant-form-item-label {
    width: 60px;
  }

  .ant-form-item-control {
    flex: 1;
  }
}

.win-open-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
}

.yl-sf {
  color: #333;
  position: absolute;
  right: 50px;
  top: 17px;
  cursor: pointer;
}

.ant-modal.full {
  width: 100%;
  max-width: none;
  width: 100% !important;
  top: 0;
  padding-bottom: 0;
  .pg-viewer-wrapper .document-container {
    width: auto;
  }
}
.mul-st-box {
  position: relative;
  .ant-select-selection-overflow {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .xp {
    position: absolute;
    right: -20px;
  }
}

.progress-aox {
  position: absolute;
  left: 0;
  top: 0;
  background: #2b2b2b;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .msg {
    text-align: center;
    margin-top: 8px;
    font-size: 12px;
  }
}
.progress-loading {
  width: 380px;
  height: 8px;
  background: #fff;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  .c {
    background: #1890ff;
    height: 100%;
    width: 15px;
    border-radius: 5px;
    transition: width 0.2s ease;
  }
}

.preloader-loadding {
  font-size: 14px;
  color: #8c90f6;
  text-align: center;
  position: absolute;
  width: 200px;
  left: 50%;
  top: 50%;
  margin: 0 0 0 -100px;
}

.preloader {
  display: block;
  position: relative;
  height: 50px;
  width: 50px;
  margin: 0 auto 10px;
  border: 5px solid transparent;
  border-top-color: #8c90f6;
  border-bottom-color: #8c90f6;
  border-radius: 50%;
  -webkit-animation: spinner1 1s linear infinite;
  animation: spinner1 1s linear infinite;
}

.preloader:after {
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border: 5px solid transparent;
  border-radius: 50%;
  background: #8c90f6;
}

/**===== spinner1 =====*/
#spinner1 {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
  border: 5px solid transparent;
  border-top-color: #8c90f6;
  border-bottom-color: #8c90f6;
  border-radius: 50%;
  -webkit-animation: spinner1 1s linear infinite;
  animation: spinner1 1s linear infinite;
}

#spinner1:after {
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border: 5px solid transparent;
  border-radius: 50%;
  background: #8c90f6;
}

@keyframes spinner1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spinner1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
/** END of spinner1 */
/*-----------------------------------------------------------------------*/

.pg-viewer-wrapper .loading-container .loading {
  display: block;
  position: relative;
  height: 50px !important;
  width: 50px !important;
  margin: 0 auto 10px;
  border: 5px solid transparent;
  border-top-color: #8c90f6;
  border-bottom-color: #8c90f6;
  border-radius: 50%;
  -webkit-animation: spinner1 1s linear infinite;
  animation: spinner1 1s linear infinite;
}

.pg-viewer-wrapper .loading-container .loading:after {
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border: 5px solid transparent;
  border-radius: 50%;
  background: #8c90f6;
}

.pg-viewer-wrapper .document-container {
  width: 100% !important;
  word-break: break-all;
  p {
    img {
      width: auto;
      max-width: 100%;
    }
  }
}

.exp_pdf_tabel {
  text-align: center;
  display: inline-block;
  min-height: 840px;
  table {
    border-top: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    font-size: 14px;
    .ht {
      margin-bottom: 12px;
    }
    td {
      border-bottom: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
      text-align: center;
      vertical-align: middle;
      padding: 5px 5px;
      line-height: 22px;
      word-wrap: break-all;
      &.x01 {
        text-align: left;
        width: 102px;
        display: block;
        word-wrap: break-all;
        word-wrap: break-word;
      }
      &.x02 {
        width: 90px;
      }
    }
  }
}

.st-xf {
  position: relative;
  .qx {
    position: absolute;
    right: 0px;
    top: 7px;
    cursor: pointer;
  }
}

.pkg-dailog {
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-close {
    right: 16px;
    top: 16px;
  }
  .ant-modal-close-x {
    visibility: hidden;
  }
}
.pkg-dg {
  height: 500px;
  background: url('~@/assets/images/pkg-bg.png') no-repeat;
  padding-top: 162px;
  .pkg-dg-ct {
    height: 338px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .sc {
      width: 100%;
      height: calc(100% - 20px);
      overflow-y: auto;
      position: absolute;
      left: 0;
      top: 0;
      padding: 15px 30px;
    }
  }
}

.jpt-modal-main {
  overflow: hidden;
  &.ant-modal {
    max-width: 100%;
  }
  .ant-modal-body {
    padding: 0 0 0  0;
  }
  .ant-modal-close {
    right: -13px;
    top: -13px;
  }
  .jpt-iframe-wrap {
    width: 100%;
    height: calc(100% - 68px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    iframe {
      width: calc(100% - 25px);
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.circles-loader:not(:required) {
  position: relative;
  text-indent: -9999px;
  display: inline-block;
  width: 25px;
  height: 25px;
  background: rgba(255, 204, 51, 0.9);
  border-radius: 100%;
  -moz-animation: circles-loader 3s infinite ease-in-out;
  -webkit-animation: circles-loader 3s infinite ease-in-out;
  animation: circles-loader 3s infinite ease-in-out;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
.circles-loader:not(:required)::before {
  background: rgba(255, 102, 0, 0.6);
  border-radius: 100%;
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  top: 18.75px;
  left: -10.82532px;
}
.circles-loader:not(:required)::after {
  background: rgba(255, 51, 0, 0.4);
  border-radius: 100%;
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  top: 18.75px;
  left: 10.82532px;
}

@keyframes circles-loader {
  0% {
    -moz-transform: rotate(-720deg);
    -ms-transform: rotate(-720deg);
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
  50% {
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}


.wt47 {
  button {
    width: 47%!important;
  }
}
.wt100 {
  button {
    width: 100%!important;
  }
}