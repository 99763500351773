.login_page {
    width: 100%;
    height: 100%;
    // background-image: url('../../assets/images/login/img_login_bg.svg');
    .login_header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 56px;
        background: #FFFFFF;
        padding: 0 128px;

        .login_user {
            display: flex;
            align-items: center;

            img {
                width: 32px;
                height: 32px;
            }

            span {
                margin-left: 16px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }

    .form_container {
        position: absolute;
        right: 128px;top: 45%;
        transform: translateY(-50%);
        // margin-right: 128px;
        width: 400px;
        // float: right;
        // padding-top: calc(50px + 3.6%);

        .form_title {
            text-align: center;

            img {
                width: 240px;
                height: 74px;
            }
        }

        .form_bg {
            width: 400px;position: relative;
            height: 440px;
            margin-top: 28px;
            background: #FFFFFF;
            // background-image: url('../../assets/images/login/img_login.svg');
            padding: 49px 52px 70px 48px;
            border-radius: 4px;

            button {
                background: #2A67F6;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                &:hover{
                    background: #497DF7;
                }
            }

            .form_tit {
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.85);
                text-align: center;
                margin-bottom: 21px;
            }

            ::-webkit-input-placeholder {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.35);
            }

            ::-moz-placeholder {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.35);
            }

            .forgetPass {
                text-align: right;
                margin-bottom: 16px;

                span {
                    cursor: pointer;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.35);
                }
            }
        }
    }

    .slide-cont {
        position: absolute;top: 40px;bottom: 0;z-index: 1;
        padding: 20px 0;background-color: #fff;
        .slide-close {
            position: absolute;top: 0; right: -15px;cursor: pointer;
        }
    }
    .check-bar {
        height: 40px;border: 1px solid #19d1b9;color: #19d1b9;
        display: flex;justify-content: center;align-items: center;cursor: pointer;
        .check-icon {font-size: 16px;margin-right: 5px;}
    }
    .check-bar-suc {
        height: 40px;color: #fff;background-color: #19d1b9;
        display: flex;justify-content: center;align-items: center;
        .check-icon {font-size: 16px;margin-right: 5px;font-weight: bold;}
    }
    .copyRight {
        position: absolute;
        bottom: 15px;
        width: 100%;
        text-align: center;
        font-size: 13px;
        line-height: 20px;
    }
}.layout_container {
  flex: 1;
  // width: 1184px;
  background: #ffffff;
  // margin: 14px 0 34px 0;
  position: relative;
}

/**
* layout = ( header ) + ( menu + (container = CommonTitle + layout_content))
*/
.layout_content {
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: @safe-padding;
  overflow: auto;
}

.layout_content_noPadding {
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  overflow: auto;
}

//menu
:global(.ant-menu) {
  width: 200px;
}

:global(.ant-menu-inline) {
  border-bottom: none;
}

:global(.ant-menu-sub.ant-menu-vertical) {
  padding: 16px !important;
  min-width: 200px;
}

:global(.ant-menu-sub > .ant-menu-item) {
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
}

/*一级菜单选中*/
:global(.ant-menu-dark > .ant-menu-item-selected) {
  background-color: #1e293e !important;
  border-left: 5px solid #37eff3 !important;
}

/*二级菜单选中*/
:global(.ant-menu-dark > .ant-menu-submenu > .ant-menu-sub > .ant-menu-item-selected) {
  background: #1e293e !important;
  border-left: 5px solid #37eff3 !important;
}

/*一级、二级菜单选中颜色*/
:global(.ant-menu-dark .ant-menu-item-selected > .ant-menu-item-icon),
:global(.ant-menu-dark .ant-menu-item-selected > .ant-menu-title-content) {
  color: #37eff3 !important;
}

/*一级、二级菜单滑过*/
:global(.ant-menu-dark > .ant-menu-item-active),
:global(.ant-menu-dark > .ant-menu-submenu > .ant-menu-sub > .ant-menu-item-active) {
  background: #1e293e !important;
  color: #37eff3 !important;
}

//每行菜单的样式，包括一级二级
:global(.ant-menu-inline > .ant-menu-item) {
  height: 46px !important;
  line-height: 46px !important;
  border-bottom: 4px solid transparent !important;
  width: 200px;
  text-align: left;
}

//具有二级菜单的父菜单样式
:global(.ant-menu-dark > .ant-menu-submenu > .ant-menu-submenu-title) {
  color: rgb(120, 139, 165);
}

:global(.ant-menu-submenu-inline .ant-menu-submenu-arrow) {
  display: block;
  position: absolute;
  right: 20px;
  color: #909399 !important;
  transform: rotate(0deg);
  font-size: 18px;
}

:global(.ant-menu-vertical > .ant-menu-item:not(:last-child)) {
  margin-bottom: 0;
  border-bottom: 1px solid #ebeff6;
}

:global(.ant-menu-submenu-arrow::before),
:global(.ant-menu-submenu-arrow::after) {
  width: 8px !important;
}

:global(.ant-menu-inline > .ant-menu-item),
:global(.ant-menu-inline > .ant-menu-submenu) {
  margin: 0;
}

:global(.ant-menu-inline .ant-menu-item:not(:last-child)) {
  margin-bottom: 0;
}
:global(.ant-menu-inline .ant-menu-selected::after) {
  display: none;
}
:global(.ant-menu-inline .ant-menu-item-selected::after) {
  display: none;
}

//other
:global(.ant-upload-select-picture-card) {
  margin: 8px 7px;
  height: calc(134px - 18px);
  width: calc(116px - 16px);
}

:global(.ant-dropdown-placement-bottomCenter) {
  min-width: 240px !important;
}

:global(.ant-dropdown-placement-bottomCenter > .ant-dropdown-menu-vertical) {
  padding: 0 16px;
}

:global(.ant-dropdown-placement-bottomCenter .ant-dropdown-menu-item > div) {
  justify-content: center;
  height: 48px;
}

:global(.ant-dropdown-placement-bottomCenter .ant-dropdown-menu-item-active) {
  background: #ffffff;
}

:global(.ant-dropdown-placement-bottomCenter .ant-dropdown-menu-item:not(:last-child)) {
  border-bottom: 1px solid #ebeff6;
}
/* 与布局、排版有关的全局样式名 */
.flex {
  display: flex;
}

.flex-r-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-r-start-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-r-start-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-r-between-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-r-end-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.flex-r-start-stretch {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.flex-c-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-c-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex-c-start-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-d-row {
  flex-direction: row;
}

.flex-d-column {
  flex-direction: column;
}

.row-wrap {
  flex-flow: row wrap;
}

.row-nowrap {
  flex-flow: row nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 垂直居中 */
.flex-vc,
.flex-a-center {
  align-items: center;
}

.flex-a-start {
  align-items: flex-start;
}

.flex-a-end {
  align-items: flex-end;
}

.flex-a-baseline {
  align-items: baseline;
}

.flex-a-stretch {
  align-items: stretch;
}

.flex-hc,
.flex-j-center {
  justify-content: center;
}

.flex-j-between {
  justify-content: space-between;
}

.flex-j-around {
  justify-content: space-around;
}

.flex-j-start {
  justify-content: flex-start;
}

.flex-j-end {
  justify-content: flex-end;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flex3 {
  flex: 3;
}

.vat {
  vertical-align: top;
}

.vab {
  vertical-align: bottom;
}

.vatp {
  vertical-align: text-top;
}

.vatb {
  vertical-align: text-bottom;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.p-fixed {
  position: fixed;
}

.top0 {
  top: 0;
}

.bottom0 {
  bottom: 0;
}

.left0 {
  left: 0;
}

.right0 {
  right: 0;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.uef-upload {
  .up-dt {
    display: flex;
    align-items: center;

    .del {
      position: relative;
      top: -2px;
      color: red;
      font-size: 18px;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .ant-upload-list {
    max-height: 300px;
    overflow: auto;
    border: 1px solid #d9d9d9;
    padding: 15px;
  }
}

@keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.pre-loading {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  &.grep {
    background: rgb(43, 43, 43);
    color: #fff;
  }

  .msg {
    margin-left: 10px;
    font-size: 14px;
  }

  .icon {
    font-size: 18px;
    margin-right: 5px;
    position: relative;
    top: 2px;
  }

  .circle {
    width: 35px;
    height: 35px;
    animation: circle infinite 0.75s linear;
    border: 2px solid #198bfd;
    border-top-color: transparent;
    border-radius: 100%;
  }
}

.render_right_del {
  color: red;
  cursor: pointer;
  margin: 0 5px;

  &:hover {
    color: #ff6a6a;
  }
}

.render_right_edit {
  color: #1890ff;
  cursor: pointer;
  margin: 0 5px;

  &:hover {
    color: #1bb7ff;
  }
}

.header__searchs {
  display: flex;
  align-items: center;
  justify-content: right;
  margin: 0 0 0 0;

  // border-bottom: 1px solid #ebeff6;
  .ant-btn-primary {
    background: #68c23b;
    border-color: #68c23b;

    &:hover {
      background: #75da42;
      border-color: #75da42;
    }
  }
}

.header_searchs_reset {
  color: #dcdcdc;
  font-size: 14px;
  margin-left: 6px;
  margin-right: 4px;
  cursor: pointer;
  width: 48px;

  &:hover {
    color: #68c23b;
  }
}

.image_frame_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  border-top: 1px solid rgb(208, 208, 216);

  .image_frame_box {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgb(43, 43, 43);
    iframe {
      height: 100%;
      width: calc(100% + 22px);
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.home-dg-editor {
  .ant-form-item {
    display: flex;
  }

  .ant-form-item-label {
    width: 60px;
  }

  .ant-form-item-control {
    flex: 1;
  }
}

.win-open-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
}

.yl-sf {
  color: #333;
  position: absolute;
  right: 50px;
  top: 17px;
  cursor: pointer;
}

.ant-modal.full {
  width: 100%;
  max-width: none;
  width: 100% !important;
  top: 0;
  padding-bottom: 0;
  .pg-viewer-wrapper .document-container {
    width: auto;
  }
}
.mul-st-box {
  position: relative;
  .ant-select-selection-overflow {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .xp {
    position: absolute;
    right: -20px;
  }
}

.progress-aox {
  position: absolute;
  left: 0;
  top: 0;
  background: #2b2b2b;
  width: 100%;
  height: 100%;
  z-index: 10;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .msg {
    text-align: center;
    margin-top: 8px;
    font-size: 12px;
  }
}
.progress-loading {
  width: 380px;
  height: 8px;
  background: #fff;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  .c {
    background: #1890ff;
    height: 100%;
    width: 15px;
    border-radius: 5px;
    transition: width 0.2s ease;
  }
}

.preloader-loadding {
  font-size: 14px;
  color: #8c90f6;
  text-align: center;
  position: absolute;
  width: 200px;
  left: 50%;
  top: 50%;
  margin: 0 0 0 -100px;
}

.preloader {
  display: block;
  position: relative;
  height: 50px;
  width: 50px;
  margin: 0 auto 10px;
  border: 5px solid transparent;
  border-top-color: #8c90f6;
  border-bottom-color: #8c90f6;
  border-radius: 50%;
  -webkit-animation: spinner1 1s linear infinite;
  animation: spinner1 1s linear infinite;
}

.preloader:after {
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border: 5px solid transparent;
  border-radius: 50%;
  background: #8c90f6;
}

/**===== spinner1 =====*/
#spinner1 {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
  border: 5px solid transparent;
  border-top-color: #8c90f6;
  border-bottom-color: #8c90f6;
  border-radius: 50%;
  -webkit-animation: spinner1 1s linear infinite;
  animation: spinner1 1s linear infinite;
}

#spinner1:after {
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border: 5px solid transparent;
  border-radius: 50%;
  background: #8c90f6;
}

@keyframes spinner1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spinner1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
/** END of spinner1 */
/*-----------------------------------------------------------------------*/

.pg-viewer-wrapper .loading-container .loading {
  display: block;
  position: relative;
  height: 50px !important;
  width: 50px !important;
  margin: 0 auto 10px;
  border: 5px solid transparent;
  border-top-color: #8c90f6;
  border-bottom-color: #8c90f6;
  border-radius: 50%;
  -webkit-animation: spinner1 1s linear infinite;
  animation: spinner1 1s linear infinite;
}

.pg-viewer-wrapper .loading-container .loading:after {
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border: 5px solid transparent;
  border-radius: 50%;
  background: #8c90f6;
}

.pg-viewer-wrapper .document-container {
  width: 100% !important;
  word-break: break-all;
  p {
    img {
      width: auto;
      max-width: 100%;
    }
  }
}

.exp_pdf_tabel {
  text-align: center;
  display: inline-block;
  min-height: 840px;
  table {
    border-top: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    font-size: 14px;
    .ht {
      margin-bottom: 12px;
    }
    td {
      border-bottom: 1px solid #d9d9d9;
      border-right: 1px solid #d9d9d9;
      text-align: center;
      vertical-align: middle;
      padding: 5px 5px;
      line-height: 22px;
      word-wrap: break-all;
      &.x01 {
        text-align: left;
        width: 102px;
        display: block;
        word-wrap: break-all;
        word-wrap: break-word;
      }
      &.x02 {
        width: 90px;
      }
    }
  }
}

.st-xf {
  position: relative;
  .qx {
    position: absolute;
    right: 0px;
    top: 7px;
    cursor: pointer;
  }
}

.pkg-dailog {
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-close {
    right: 16px;
    top: 16px;
  }
  .ant-modal-close-x {
    visibility: hidden;
  }
}
.pkg-dg {
  height: 500px;
  background: url('~@/assets/images/pkg-bg.png') no-repeat;
  padding-top: 162px;
  .pkg-dg-ct {
    height: 338px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .sc {
      width: 100%;
      height: calc(100% - 20px);
      overflow-y: auto;
      position: absolute;
      left: 0;
      top: 0;
      padding: 15px 30px;
    }
  }
}

.jpt-modal-main {
  overflow: hidden;
  &.ant-modal {
    max-width: 100%;
  }
  .ant-modal-body {
    padding: 0 0 0  0;
  }
  .ant-modal-close {
    right: -13px;
    top: -13px;
  }
  .jpt-iframe-wrap {
    width: 100%;
    height: calc(100% - 68px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    iframe {
      width: calc(100% - 25px);
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.circles-loader:not(:required) {
  position: relative;
  text-indent: -9999px;
  display: inline-block;
  width: 25px;
  height: 25px;
  background: rgba(255, 204, 51, 0.9);
  border-radius: 100%;
  -moz-animation: circles-loader 3s infinite ease-in-out;
  -webkit-animation: circles-loader 3s infinite ease-in-out;
  animation: circles-loader 3s infinite ease-in-out;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
.circles-loader:not(:required)::before {
  background: rgba(255, 102, 0, 0.6);
  border-radius: 100%;
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  top: 18.75px;
  left: -10.82532px;
}
.circles-loader:not(:required)::after {
  background: rgba(255, 51, 0, 0.4);
  border-radius: 100%;
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  top: 18.75px;
  left: 10.82532px;
}

@keyframes circles-loader {
  0% {
    -moz-transform: rotate(-720deg);
    -ms-transform: rotate(-720deg);
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
  50% {
    -moz-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}


.wt47 {
  button {
    width: 47%!important;
  }
}
.wt100 {
  button {
    width: 100%!important;
  }
}
@color_white: #ffffff;
@color_divider: #EBEFF6;
@color_primary: #1890ff;
@color_readme: rgba(0, 0, 0, 0.35);
@color_gray: rgb(163, 164, 165);
@safe-padding: 24px;
@import '../../styles/reset.less';
@import '../../styles/global-class.less';
@import '../../styles/gloabl-mixins.less';
@import '../../styles/global-variable.less';
@import '../../styles/global-antd-custom.less';
@import '../../styles/scroll-bar.less';

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
}

.App {
  min-width: 1000px;
  overflow-x: auto;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

//通用md的浏览样式
.md_doc {
  flex: 1;
  padding: 0 18px;
  overflow: auto;
  max-height: 100%;
  font-family: 'PingFang SC';
  font-size: 14px;
  line-height: 26px;
  background: #fff;
  &::-webkit-scrollbar {
    width: 0px;
  }
  h1{
    font-size: 22px;
    font-weight: 500;
    line-height: 31px;
  }
  h2{
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
  }
  h3{
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
  }
  h4, h5{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  h1, h2, h3, h4, h5, h6, li{
    margin-top: 10px;
  }
  p {
    white-space: normal;
    word-break: break-all;
    margin-top: 10px;
  }
  pre {
    overflow: visible !important;
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    overflow: auto;
    word-break: break-all;
    word-wrap: break-word;
    background: rgb(246 248 250);
    padding: 10px 10px 10px 15px;
    margin:15px auto;
    //border:1px solid rgba(255, 255, 255, 0.1);
    //border-left:4px solid #58749c;
    //letter-spacing:1.5px;
    font-size: 13px;
    font-weight: 500;
    line-height:22px;
    border-radius: 6px;
  }
  ul{
    margin-left: 1em;
  }
  li {
    line-height: 20px;
  }
  table {
    //border: 1px solid rgb(221, 221, 221);
    margin-top: 8px;
    thead {
      tr th {
        border-bottom: 1px solid rgb(123, 129, 132);
        border-right: 1px solid rgb(123, 129, 132);
        padding: 5px 8px;
        color: #fff;
        background: rgb(123, 129, 132);
      }
    }
    tbody {
      tr td {
        border: 1px solid rgb(221, 221, 221);
        padding: 5px 8px;
        //color: rgba(255, 255, 255, 0.45);
      }
    }
  }
  img {
    max-width: 100%;
  }
  >p>label{
    width: 100% !important;
    display: inline-block !important;
    text-align: center !important;
  }
}


#home-editor {
  height: 300px !important;
}

.report_modal {
  :global(.ant-modal-footer) {
    border-top: none;
    padding: 0px 16px 20px;
  }
  :global(.ant-modal-body) {
    padding: 24px 24px 0px !important;
  }
  :global(.ant-form-item) {
    margin-bottom: 15px;
  }
  

  .footer_btns {
    margin-right: 8px;
    button {
      font-size: 14px;
      padding: 8px 20px;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      height: auto;
      span {
        line-height: 20px;
      }
    }
  }

  .btn_cancel {
    border: 1px solid #dcdfe6;
    color: #606266;
  }

  .btn_blue {
    background: #409eff;
    border-color: #409eff;
    color: #fff;
  }

  .btn_orange {
    background: #e6a23c;
    border-color: #e6a23c;
    color: #fff;
  }

  .error_tip {
    color: #ff4d4f;
    font-size: 14px;
    line-height: 1;
    padding-top: 4px;
    margin-top: 5px;
  }

  :global(.ant-form-item-label > label::before) {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }

  .report_review {
    width: 100%;
    height: 450px;
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
    .report_review_title {
      font-size: 18px;
      color: #333;
      font-weight: bold;
      text-align: center;
    }
    .report_review_line {
      height: 1px;
      width: 100%;
      background: #eaeaea;
      margin: 15px 0 15px 0;
    }
    .report_review_info {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      &>div {
        width: 33.3%;
        text-align: center;
        code {
          font-weight: bold;
          color: #333;
        }
        
        span {
          color: #151515;
          display: inline-block;
          text-align: left;
          white-space: nowrap;
        }
      }
    }
    
    .report_review_content {
      color: #666;
      font-size: 14px;
      img{
        width: 100%;
        margin: 15px 0;
      }
    }
  }

}
.pdf-viewer-container{
  .pdf-canvas{
    text-align: center;
  }
}

#docx{
  color: #000;
    h1, h2, h3, h4, h5, h6, p, li, ol, ul, a{
      color: #000 !important;
    }
    p, li{
      line-height: 25px !important;
    }
    table{
      border: 1px solid #ccc;
    }
    
}
.pg-viewer{
  width: 100% !important;
}

// 镜像模式下的文档样式
#image_compatible_left{
  position: relative;
  z-index: 100;
  & > div{
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .pg-viewer-wrapper .document-container{
    width: 100% !important;
    font-size: 14px;
    line-height: 24px;
    padding: 10px;
  }
  #docx{
    p, li{
      word-break: break-all;
    }
  }
  .pg-viewer-wrapper{
    .pdf-loading{
      color: #FFFFFF !important;
    }
  }

  .pg-viewer-wrapper ol li {
    display: block;
  }
  .document-container {
    &>p {
      text-indent: 2em;
      margin-top: 0.6em;
      margin-bottom: 0.6em;
    }
    &>ul{ 
      margin-top: 0.6em;
      margin-bottom: 0.6em; 
    }
    &>ol { //代码格式
      margin-left: 2em;
    }
    &>p>img {
      margin-left: -2em;
      max-width: 100%;
      width: auto;
    }
    &>ul>li>ul>li {
      list-style: square;
    }
    &>ul>li:only-child {
      list-style: none;
    }
    &>table>tbody>tr>td>p{
      line-height: 25px !important;
      margin: 0.2em 0;
    }
  }
}
#file_container{
  .pg-viewer-wrapper{
    .pdf-loading{
      color: #000 !important;
    }
  }
  #docx{
    p, li{
      word-break: break-all;
    }
  }
}
.no_data{
  text-align: center;
  position: absolute;
  left: 50%;
  top:50%;
  -webkit-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%); 
  font-size: 16px;
  color: rgba(0, 0, 0, 0.25);
  .no_data_text{
    margin-top: 10px;
  }
}

.w-e-bar-divider {
  display: none!important;
}

.case-online {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 100000000000;
  .close {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size:20px;
    cursor: pointer;
  }
}


.vwupl_view_box {
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  .pg-viewer-wrapper .loading-container {
      display: none;
  }

  .vw {
      width: 100%;
      height: 100%;
      position: relative;
      left: 0;
      top: 0;
      z-index: 101;

      overflow: hidden;

      .vm-bg {
        width: 100%;
        height: 100%;
      }
  }
}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
	border-radius: 4px;
	background-color:#ffffff;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
	border-radius: 4px;
	-webkit-box-shadow: inset 0 0 6px  #79a9f5;
  background-color: #B2CFFF;
  cursor: pointer;
}
