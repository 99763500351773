//menu
:global(.ant-menu) {
  width: 200px;
}

:global(.ant-menu-inline) {
  border-bottom: none;
}

:global(.ant-menu-sub.ant-menu-vertical) {
  padding: 16px !important;
  min-width: 200px;
}

:global(.ant-menu-sub > .ant-menu-item) {
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
}

/*一级菜单选中*/
:global(.ant-menu-dark > .ant-menu-item-selected) {
  background-color: #1e293e !important;
  border-left: 5px solid #37eff3 !important;
}

/*二级菜单选中*/
:global(.ant-menu-dark > .ant-menu-submenu > .ant-menu-sub > .ant-menu-item-selected) {
  background: #1e293e !important;
  border-left: 5px solid #37eff3 !important;
}

/*一级、二级菜单选中颜色*/
:global(.ant-menu-dark .ant-menu-item-selected > .ant-menu-item-icon),
:global(.ant-menu-dark .ant-menu-item-selected > .ant-menu-title-content) {
  color: #37eff3 !important;
}

/*一级、二级菜单滑过*/
:global(.ant-menu-dark > .ant-menu-item-active),
:global(.ant-menu-dark > .ant-menu-submenu > .ant-menu-sub > .ant-menu-item-active) {
  background: #1e293e !important;
  color: #37eff3 !important;
}

//每行菜单的样式，包括一级二级
:global(.ant-menu-inline > .ant-menu-item) {
  height: 46px !important;
  line-height: 46px !important;
  border-bottom: 4px solid transparent !important;
  width: 200px;
  text-align: left;
}

//具有二级菜单的父菜单样式
:global(.ant-menu-dark > .ant-menu-submenu > .ant-menu-submenu-title) {
  color: rgb(120, 139, 165);
}

:global(.ant-menu-submenu-inline .ant-menu-submenu-arrow) {
  display: block;
  position: absolute;
  right: 20px;
  color: #909399 !important;
  transform: rotate(0deg);
  font-size: 18px;
}

:global(.ant-menu-vertical > .ant-menu-item:not(:last-child)) {
  margin-bottom: 0;
  border-bottom: 1px solid #ebeff6;
}

:global(.ant-menu-submenu-arrow::before),
:global(.ant-menu-submenu-arrow::after) {
  width: 8px !important;
}

:global(.ant-menu-inline > .ant-menu-item),
:global(.ant-menu-inline > .ant-menu-submenu) {
  margin: 0;
}

:global(.ant-menu-inline .ant-menu-item:not(:last-child)) {
  margin-bottom: 0;
}
:global(.ant-menu-inline .ant-menu-selected::after) {
  display: none;
}
:global(.ant-menu-inline .ant-menu-item-selected::after) {
  display: none;
}

//other
:global(.ant-upload-select-picture-card) {
  margin: 8px 7px;
  height: calc(134px - 18px);
  width: calc(116px - 16px);
}

:global(.ant-dropdown-placement-bottomCenter) {
  min-width: 240px !important;
}

:global(.ant-dropdown-placement-bottomCenter > .ant-dropdown-menu-vertical) {
  padding: 0 16px;
}

:global(.ant-dropdown-placement-bottomCenter .ant-dropdown-menu-item > div) {
  justify-content: center;
  height: 48px;
}

:global(.ant-dropdown-placement-bottomCenter .ant-dropdown-menu-item-active) {
  background: #ffffff;
}

:global(.ant-dropdown-placement-bottomCenter .ant-dropdown-menu-item:not(:last-child)) {
  border-bottom: 1px solid #ebeff6;
}
